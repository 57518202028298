@import "@/styles/variables";
@import "@/styles/mixins";

.appBanner {
  //position: fixed;
  //top: 0;
  //bottom: 0;
  //left: 0;
  //right: 0;
  //z-index: $z-index-maximal;
  //display: flex;
  //flex-direction: column;
  //justify-content: flex-end;
  //background-color: $color-black-op-50;

  &_container {
    position: relative;
    display: flex;
    align-items: center;
    padding: $spacing-5 $spacing-3 $spacing-4;
    border-bottom: 1px solid $color-gray;
    background-color: #fff;
  }

  &_close {
    //position: absolute;
    //top: 0;
    //right: 0;
    //z-index: 1;
    padding: $spacing-2;
    background-color: unset;
    border: none;
    line-height: 0;
  }

  &_closeIcon {
    line-height: 0;
    font-size: $font-size-l;
    //color: #fff;
  }

  &_content {
    display: flex;
    width: 100%;
    align-items: center;
    //justify-content: space-between;
    gap: $spacing-2;
  }

  &_info {
    //display: flex;
    gap: $spacing-3;
    margin-right: auto;
  }

  &_imageContainer {
    position: relative;
    min-height: 100%;
    width: 40%;
    max-width: 160px;
  }

  &_image {
    object-fit: cover;
    object-position: top right;
  }

  &_button {
    text-wrap: nowrap;
    background-color: $color-black;
    color: #fff;
    font-size: $font-size-xs;
    padding: $spacing-1 $spacing-4;
    line-height: 22px;
    border-radius: $border-radius-base;
  }
}

.banner_logo {
  padding: $spacing-2;
  background-color: $color-green-main;
  border-radius: $border-radius-base;
  line-height: 0;
}

.banner_title {
  margin-bottom: $spacing-1;
  font-size: $font-size-s;
  font-weight: 500;
}

.banner_text {
  font-size: $font-size-xs;
  color: $color-grey4;
}

.app_rating {
  color: $color-yellow;

  & > svg:not(:last-child) {
    margin-right: $spacing-1;
  }
}

.app_rating_mobile {
  margin-bottom: $spacing-3;
  text-align: center;
}

.app_rating_tablet {
  display: none;
}

.app_download {
  display: inline-block;
  text-align: center;
  z-index: 1;
}

//.app_image {
//  position: absolute;
//  top: -15px;
//  left: 10px;
//}

@include for-screen-from-768 {
  .app_rating_mobile {
    display: none;
  }
  //.banner_text {
  //  display: block;
  //  font-size: $font-size-xxl;
  //  margin-right: $spacing-6;
  //}

  //.app_image {
  //  left: 280px;
  //}
}
